import "jquery.scrollto";
import ky from "ky";
import shallow from "zustand/shallow";
import { Method } from "../types/global.js";
import { changeCollectionMethod } from "../v3/checkout.changeMethod.js";
import {
  customerDataHandler,
  deliveryDataHandler,
} from "../v3/checkout.customerHandler.js";
import { makeMethodTemplate } from "../v3/checkout.methodTemplate.js";
import { getShippingPrices } from "../v3/shippingApi.getShippingPrices.js";
import { getState, setState, subscribe } from "../v3/store.js";
import {
  isNotNullUndefinedOrEmpty,
  isNullUndefinedOrEmpty,
  wait,
} from "../v3/utils.js";
import { getShippingProducts } from "./shipping.js";
import {
  getDeliveryCountry,
  getSessionId,
  getSessionValues,
} from "./shopFunctions.js";

export const initCheckout = async () => {
  // @ts-expect-error
  window.loadShippingServices = function () {
    return true;
  };

  const state = getState();
  window.getState = getState;
  window.state = state;

  $("#confirm-complete-button")
    .removeAttr("onclick")
    .on("click", async () => {
      const state = getState();

      //----------------------------

      await refreshBasketProducts();

      let shippingProduct = basketProducts.find((product) =>
        product.productNumber.toLowerCase().startsWith("fragt-")
      );

      if (shippingProduct === undefined) {
        const session = await getSessionValues();

        const sessionShippingProduct = session.basket.find((product) =>
          product.productNumber.toLowerCase().startsWith("fragt-")
        );

        if (sessionShippingProduct !== undefined) {
          shippingProduct = {
            productNumber: sessionShippingProduct.productNumber,
            name: sessionShippingProduct.productName,
            image: sessionShippingProduct.productImageUrl,
            quantity: sessionShippingProduct.quantity,
            url: `/shop/default.asp?productid=${sessionShippingProduct.productNumber}`,
            unitPriceString: sessionShippingProduct.unitPrice.toString(),
            totalPriceString: sessionShippingProduct.totalPrice.toString(),
          };
        }
      }

      const sessionId = getSessionId();

      if (
        shippingProduct !== undefined &&
        shippingProduct.productNumber !== `fragt-${sessionId}`
      ) {
        const shipping = await getShippingPrices({
          sessionId: getSessionId(),
          products: getShippingProducts(),
          zipCode: getZipCode(),
          shippingName: siteShippingName,
          deliveryCountry: await getDeliveryCountry(),
          currency: siteCurrency,
        });

        state.setShipping(shipping);

        await ky.get(
          `/shop/showbasket.asp?AddMultiple=1&Amount=|1|-10&ProductID=|fragt-${sessionId}|${shippingProduct.productNumber}`
          // { fetch: window.kyFetch }
        );

        loadOrderSummary(true);

        $(window).scrollTo(".forsendelse");

        return;
      }

      if (shippingProduct === undefined) {
        const shipping = await getShippingPrices({
          sessionId: getSessionId(),
          products: getShippingProducts(),
          shippingName: siteShippingName,
          deliveryCountry: await getDeliveryCountry(),
          zipCode: getZipCode(),
          currency: siteCurrency,
        });

        state.setShipping(shipping);

        await ky.get(
          `/shop/basket.asp?add=1&amount=1&productid=fragt-${sessionId}`
          // { fetch: window.kyFetch }
        );

        loadOrderSummary(true);

        $(window).scrollTo(".forsendelse");

        return;
      }

      // ---------------------------

      const $customerDataForm = $("#column-data > #customer-data > form");

      const $dsShippingCollections = $(".ds-shipping-collections");

      if ($dsShippingCollections.length === 0) {
        makeCollections().then(() => {
          const $zipInput = $customerDataForm.find(
            "#checkout_customer_zipcode"
          );

          const zip = $zipInput.val()?.toString() || "";

          $zipInput.val("");

          $customerDataForm.trigger("blur");

          $zipInput.val(zip);

          $customerDataForm.trigger("blur");
          $(window).scrollTo(".forsendelse");
        });

        return;
      }

      const $selectedShop = $(
        ".ds-gls-parsel-shops--container .selected-postal"
      ).first();

      if (state.parcelshopSelected() || $selectedShop.length > 0) {
        let shopId = $selectedShop.find(".delivery-pickup-gls-shopid").text();
        shopId =
          shopId.toLowerCase().indexOf("pakkeshop") >= 0
            ? shopId
            : shopId.indexOf(":") >= 0
            ? `Pakkeshop: ${shopId.split(":")[1].trim()}`
            : `Pakkeshop: ${shopId}`;
        const shopName = $selectedShop.find(".delivery-pickup-gls-name").text();
        const shopAddress = $selectedShop
          .find(".delivery-pickup-gls-streetname")
          .text();
        const shopCity = $selectedShop
          .find(".delivery-pickup-gls-postal-zip")
          .text(); // Yes, they are switched in the source from DD.
        const shopZip = $selectedShop
          .find(".delivery-pickup-gls-postal-city")
          .text();
        const shopCountry = state.checkout.customer.checkout_customer_country;

        const customerPhone = state.checkout.customer.checkout_customer_phone;

        const customerName = state.checkout.customer.checkout_customer_name;

        $("#checkout_altdelivery_customertype").val(1).trigger("change");

        const $deliveryDataForm = $(
          "#customer-alternative-delivery > #customer-data > form"
        );

        $deliveryDataForm
          .find("#checkout_customer_altdelivery_country")
          .val((_, value) => (value === shopCountry ? value : shopCountry))
          .trigger("change")
          .end()
          .find("#checkout_customer_altdelivery_zipcode")
          .val(shopZip)
          .end()
          .find("#checkout_customer_altdelivery_city")
          .val(shopCity)
          .end()
          .find("#checkout_customer_altdelivery_name")
          .val(customerName)
          .end()
          .find("#checkout_customer_altdelivery_company")
          .val(shopName)
          .end()
          .find("#checkout_customer_altdelivery_address")
          .val(shopAddress)
          .end()
          .find("#checkout_customer_altdelivery_address2")
          .val(shopId)
          .end()
          .find("#checkout_customer_altdelivery_phone")
          .val(customerPhone)
          .end()
          .trigger("blur");
      }

      if (state.parcelshopSelected() && $selectedShop.length === 0) {
        const $zipInput = $customerDataForm.find("#checkout_customer_zipcode");

        const zip = $zipInput.val()?.toString() || "";

        $zipInput.val("");

        $customerDataForm.trigger("blur");

        $zipInput.val(zip);

        $customerDataForm.trigger("blur");
        $(window).scrollTo(".forsendelse");
        return;
      }

      let selection = state.shipping?.collections.map((collection) => {
        return {
          m: collection.selected.methodId,
          ps: collection.selected.products.map((p) => ({
            id: p.productNumber,
            q: p.qty,
          })),
          pr: collection.selected.price,
        };
      });

      if (selection === undefined) {
        const shippingProducts = getShippingProducts();
        selection = (
          await getShippingPrices({
            sessionId: getSessionId(),
            products: shippingProducts,
            currency: siteCurrency,
            deliveryCountry: await getDeliveryCountry(),
            zipCode: getZipCode(),
            shippingName: siteShippingName,
          })
        ).collections.map((collection) => {
          return {
            m: collection.selected.methodId,
            ps: collection.selected.products.map((p) => ({
              id: p.productNumber,
              q: p.qty,
            })),
            pr: collection.selected.price,
          };
        });
      }

      $(".res3 > input#res3").val(
        JSON.stringify(selection ?? { m: 0, ps: [], pr: 0 })
      );

      processOrder();
    });

  if ($("#customer-data > form").length === 0) {
    $("#customer-data > fieldset").wrap('<form method="post"></form>');
  }

  $("div#radio_customertype").remove();

  $("input#checkout_customer_altdelivery_address2").attr("tabindex", "-1");

  $("#checkout_use_alternative_delivery_address")
    .removeAttr("onclick")
    .on("click", async (event: JQuery.ClickEvent) => {
      const state = getState();
      $("#customer-alternative-delivery").toggle();

      const checked = event.delegateTarget.checked;

      $(event.delegateTarget).val((_, value) => (value === "1" ? "0" : "1"));

      ky.get(
        `/shop/AjaxCheckOutWrapper.asp?action=togglealternativedelivery&checked=${
          checked ? 1 : 0
        }&_=${Date.now()}`
        // { fetch: window.kyFetch }
      );

      const $deliveryDataForm = $(
        "#customer-alternative-delivery > #customer-data > form"
      );

      if (checked && !state.parcelshopSelected()) {
        const shopCountry = state.checkout.customer.checkout_customer_country;

        $deliveryDataForm
          .find("#checkout_customer_altdelivery_country")
          .val((_, value) =>
            value === null
              ? shopCountry
              : value === shopCountry
              ? value
              : shopCountry
          );
      } else if (!checked && !state.parcelshopSelected()) {
        $deliveryDataForm
          .find("#checkout_customer_altdelivery_country")
          .val([]);
      }

      // const state = getState();

      // if (checked && state.parcelshopSelected()) {
      //   const $deliveryDataForm = $('#customer-alternative-delivery > #customer-data > form');

      //   state.clearDelivery();

      //   $deliveryDataForm
      //     .find('#checkout_customer_altdelivery_country')
      //     .val([])
      //     .trigger('change')
      //     .end()
      //     .find('#checkout_customer_altdelivery_zipcode')
      //     .val('')
      //     .end()
      //     .find('#checkout_customer_altdelivery_city')
      //     .val('')
      //     .end()
      //     .find('#checkout_customer_altdelivery_name')
      //     .val('')
      //     .end()
      //     .find('#checkout_customer_altdelivery_address')
      //     .val('')
      //     .end()
      //     .find('#checkout_customer_altdelivery_address2')
      //     .val('')
      //     .end()
      //     .find('#checkout_customer_altdelivery_phone')
      //     .val('')
      //     .end()
      //     .trigger('blur');
      // }
    });

  await makeCollections();

  const $customerDataForm = $("#column-data > #customer-data > form");

  for (const [key, value] of Object.entries(state.checkout.customer)) {
    if (value === "") continue;

    const $input = $customerDataForm.find(`#${key}`);

    if (key === "checkout_use_alternative_delivery_address" && value === "1") {
      // $input.trigger('click');
      continue;
    }

    $input.val(value);

    if (key === "checkout_customertype" && value !== "0") {
      window.customerTypeChanged();
      await makeCollections();
    }
  }

  $customerDataForm
    .find("#checkout_customertype")
    .on("change blur", async () => {
      await makeCollections();
    });

  $customerDataForm.on(
    "change blur",
    "#checkout_customer_country",
    async () => {
      window.deliveryCountryChanged();

      const session = await getSessionValues(true);

      setState((state) => {
        state.session = session;
      });

      const shippingProducts = getShippingProducts();

      const shippingPrices = await getShippingPrices({
        sessionId: getSessionId(),
        products: shippingProducts,
        currency: siteCurrency,
        deliveryCountry: await getDeliveryCountry(true),
        zipCode: getZipCode(),
        shippingName: siteShippingName,
      });

      basketProducts = basketProducts.map((product) => {
        return {
          ...product,
          totalPriceString:
            session.basket
              .find((bp) => bp.productNumber === product.productNumber)
              ?.totalPrice.toLocaleString(siteLocale, {
                style: "decimal",
                minimumFractionDigits: 2,
              }) ?? "",
        };
      });

      state.setShipping(shippingPrices);

      window.loadOrderSummary(true);

      await makeCollections();

      window.loadOrderSummary(true);
    }
  );

  // $customerDataForm.on("blur", "#checkout_customer_zipcode", async (event) => {
  //   const $target = $(event.target);
  //   const zip = $target.val()?.toString() ?? "";

  //   if (zip.length < 4) return;

  //   const session = await getSessionValues(true);

  //   setState((state) => {
  //     state.session = session;
  //   });

  //   const shippingProducts = getShippingProducts();

  //   const shippingPrices = await getShippingPrices({
  //     sessionId: getSessionId(),
  //     products: shippingProducts,
  //     currency: siteCurrency,
  //     deliveryCountry: await getDeliveryCountry(true),
  //     zipCode: getZipCode(),
  //     shippingName: siteShippingName,
  //   });

  //   basketProducts = basketProducts.map((product) => {
  //     return {
  //       ...product,
  //       totalPriceString:
  //         session.basket
  //           .find((bp) => bp.productNumber === product.productNumber)
  //           ?.totalPrice.toLocaleString(siteLocale, {
  //             style: "decimal",
  //             minimumFractionDigits: 2,
  //           }) ?? "",
  //     };
  //   });

  //   state.setShipping(shippingPrices);

  //   window.loadOrderSummary(true);

  //   await makeCollections();

  //   window.loadOrderSummary(true);
  // });

  $customerDataForm.trigger("blur");

  $customerDataForm
    .on("change", customerDataHandler)
    .on("blur", customerDataHandler);

  const $deliveryDataForm = $(
    "#customer-alternative-delivery > #customer-data > form"
  );

  $deliveryDataForm.find("#checkout_customer_altdelivery_country").val([]);

  $deliveryDataForm.find('input[type="text"]').removeAttr("value");

  setTimeout(() => {
    window.loadOrderSummary(true);
  }, 250);

  // if (
  //   state.checkout.customer.checkout_use_alternative_delivery_address === '1' &&
  //   state.parcelshopSelected() === false
  // ) {
  //   for (const [key, value] of Object.entries(state.checkout.delivery)) {
  //     if (value === '') continue;

  //     const $input = $deliveryDataForm.find(`#${key}`);

  //     $input.val(value);
  //   }

  //   $deliveryDataForm.trigger('blur');
  // }

  // if (key === "#checkout_customer_country" && value === "2423") {
  //   console.log(`country value: ${value}`);

  //   window.deliveryCountryChanged();

  //   const session = await getSessionValues(true);

  //   setState((state) => {
  //     state.session = session;
  //   });

  //   const shippingProducts = getShippingProducts();

  //   const shippingPrices = await getShippingPrices({
  //     sessionId: getSessionId(),
  //     products: shippingProducts,
  //     currency: siteCurrency,
  //     deliveryCountry: await getDeliveryCountry(true),
  //     shippingName: siteShippingName,
  //   });

  //   state.setShipping(shippingPrices);

  //   window.loadOrderSummary(true);

  //   await makeCollections();

  //   window.loadOrderSummary(true);

  // }

  $deliveryDataForm
    .on("change", deliveryDataHandler)
    .on("blur", deliveryDataHandler);

  // subscribe(
  //   (state) => ({ phone: state.checkout.customer.checkout_customer_phone }),
  //   ({ phone }) => {
  //     const parcelshopSelected = getState().parcelshopSelected();

  //     if (parcelshopSelected) {
  //       const $deliveryDataForm = $("#customer-alternative-delivery > #customer-data > form");

  //       $deliveryDataForm.find("#checkout_customer_altdelivery_phone").val(phone);
  //     }
  //   }
  // );

  subscribe(
    (state) => ({
      parcelshopSelected: state.parcelshopSelected(),
      zip: state.checkout.customer.checkout_customer_zipcode,
      address: state.checkout.customer.checkout_customer_address,
    }),
    async ({ parcelshopSelected, zip, address }) => {
      console.log({ parcelshopSelected, zip, address });

      const state = getState();

      const $parcelshopMethods = $(
        '.ds-shipping-method[data-parcelshop="true"]'
      );

      const $parcelshopContainers = $(".ds-gls-parsel-shops--container");

      const $altDeliveryCheckbox = $<HTMLInputElement>(
        "#checkout_use_alternative_delivery_address"
      );

      const $altDeliveryContainer = $(".checkout-row").has(
        "#checkout_use_alternative_delivery_address"
      );

      const $deliveryDataForm = $(
        "#customer-alternative-delivery > #customer-data > form"
      );

      if (parcelshopSelected === false) {
        console.log("test");
        if ($parcelshopContainers.length > 0) {
          $parcelshopContainers.remove();
        }
        if ($altDeliveryCheckbox.prop("checked") === true) {
          // $altDeliveryCheckbox.trigger('click');

          $altDeliveryCheckbox.prop("checked", false);

          $altDeliveryCheckbox.val(0);

          ky.get(
            `/shop/AjaxCheckOutWrapper.asp?action=togglealternativedelivery&checked=0&_=${Date.now()}`
            // {
            //   fetch: window.kyFetch,
            // }
          );
        }
        if ($altDeliveryContainer.is(":hidden")) {
          $altDeliveryContainer.show();
        }

        addNumber();

        return;
      }

      if ($parcelshopMethods.length <= 0) return;

      console.log("Debug 1: Parcelshop selected");

      $(
        "#customer-data > form > fieldset > .checkout-row > div.div-checkout-checkbox"
      ).addClass("alt-parcelshop");

      $("#customer-alternative-delivery")
        .attr("tabindex", "-1")
        .addClass("alt-parcelshop")
        .show();

      $(
        "#customer-alternative-delivery .column-heading h3:not(.block-number)"
      ).hide();

      addNumber();

      if (
        $(".checkout-row")
          .has("#checkout_use_alternative_delivery_address")
          .is(":visible")
      ) {
        $(".checkout-row")
          .has("#checkout_use_alternative_delivery_address")
          .hide();
      }

      if ($altDeliveryCheckbox.prop("checked") === false) {
        // $altDeliveryCheckbox.trigger('click');

        $altDeliveryCheckbox.prop("checked", true);

        $altDeliveryCheckbox.val(1);

        ky.get(
          `/shop/AjaxCheckOutWrapper.asp?action=togglealternativedelivery&checked=1&_=${Date.now()}`
          // {
          //   fetch: window.kyFetch,
          // }
        );
      }

      setState((state) => {
        state.checkout.customer.checkout_use_alternative_delivery_address = "1";
      });

      if (isNullUndefinedOrEmpty(zip) || isNullUndefinedOrEmpty(address)) {
        state.clearDelivery();

        $deliveryDataForm
          .find("#checkout_customer_altdelivery_country")
          .val([])
          .trigger("change")
          .end()
          .find("#checkout_customer_altdelivery_zipcode")
          .val("")
          .end()
          .find("#checkout_customer_altdelivery_city")
          .val("")
          .end()
          .find("#checkout_customer_altdelivery_name")
          .val("")
          .end()
          .find("#checkout_customer_altdelivery_address")
          .val("")
          .end()
          .find("#checkout_customer_altdelivery_address2")
          .val("")
          .end()
          .find("#checkout_customer_altdelivery_phone")
          .val("")
          .end()
          .trigger("blur");

        return;
      }

      console.log("Debug 2: Parcelshop selected");

      // TODO: Get correct shipping method ID by site

      const $parcelshopHTML = await (async function () {
        return $(
          `<div class="ds-gls-parsel-shops--container gls-parsel-shops--container">${await ky
            .get(
              `https://www.nardocar.dk/shop/AjaxCheckOutWrapper.asp?action=shippingservices&shippingmethod=59&shippingmethodgateway=1&country=${$(
                "#checkout_customer_country"
              ).val()}&zip=${$("#checkout_customer_zipcode").val()}&address=${$(
                "#checkout_customer_address"
              ).val()}&address2=undefined&city=${$(
                "#checkout_customer_city"
              ).val()}&email=${$(
                "#checkout_customer_email"
              ).val()}&customerType=${$(
                "#checkout_customertype"
              ).val()}&_=${Date.now()}`
              // { fetch: kyFetch }
            )
            .text()}</div>`
        );
      })();

      $parcelshopHTML
        .find(".delivery-pickup-gls-showinfo")
        .attr("style", "padding-left: 5px !important;")
        .on("click", (event: JQuery.ClickEvent) => {
          $(event.target)
            .parent()
            .siblings(".delivery-pickup-delivery-openinghours")
            .toggle(200);
        })
        .end()
        .find(".delivery-pickup-change-delvery")
        .on("click", (event: JQuery.ClickEvent) => {
          const $target = $(event.target);

          $target.hide();

          const $hiddenParcelshops = $target
            .siblings(".parcelshops-maps-dialog")
            .find(".shipping-parcel-shop--item.delivery-pickup-gls-item")
            .not(".selected-postal");

          $hiddenParcelshops.fadeIn();
        })
        .end()
        .find(".shipping-parcel-shop--item.delivery-pickup-gls-item")
        .css("cursor", "pointer")
        .on("click", (event: JQuery.ClickEvent) => {
          const $target = $(event.delegateTarget);

          const state = getState();

          if ($target.hasClass("selected-postal")) {
            $target
              .siblings(".shipping-parcel-shop--item.delivery-pickup-gls-item")
              .fadeOut();
          } else {
            $target
              .addClass("selected-postal")
              .prependTo($target.parent())
              .find(".delivery-pickup-gls-checkbox")
              .addClass("checked")
              .find('input[type="radio"]')
              .prop("checked", true);
            $target
              .siblings(".shipping-parcel-shop--item.delivery-pickup-gls-item")
              .removeClass("selected-postal")
              .fadeOut()
              .find(".delivery-pickup-gls-checkbox")
              .removeClass("checked")
              .find('input[type="radio"]')
              .prop("checked", false);
          }

          let shopId = $parcelshop.find(".delivery-pickup-gls-shopid").text();
          shopId =
            shopId.toLowerCase().indexOf("pakkeshop") >= 0
              ? shopId
              : shopId.indexOf(":") >= 0
              ? `Pakkeshop: ${shopId.split(":")[1].trim()}`
              : `Pakkeshop: ${shopId}`;
          const shopName = $target.find(".delivery-pickup-gls-name").text();
          const shopAddress = $target
            .find(".delivery-pickup-gls-streetname")
            .text();
          const shopCity = $target
            .find(".delivery-pickup-gls-postal-zip")
            .text(); // Yes, they are switched in the source from DD.
          const shopZip = $target
            .find(".delivery-pickup-gls-postal-city")
            .text();
          const shopCountry = state.checkout.customer.checkout_customer_country;

          const customerPhone = state.checkout.customer.checkout_customer_phone;

          const customerName = state.checkout.customer.checkout_customer_name;

          $("#checkout_altdelivery_customertype").val(1).trigger("change");

          $deliveryDataForm
            .find("#checkout_customer_altdelivery_country")
            .val((_, value) => (value === shopCountry ? value : shopCountry))
            .trigger("change")
            .end()
            .find("#checkout_customer_altdelivery_zipcode")
            .val(shopZip)
            .end()
            .find("#checkout_customer_altdelivery_city")
            .val(shopCity)
            .end()
            .find("#checkout_customer_altdelivery_name")
            .val(customerName)
            .end()
            .find("#checkout_customer_altdelivery_company")
            .val(shopName)
            .end()
            .find("#checkout_customer_altdelivery_address")
            .val(shopAddress)
            .end()
            .find("#checkout_customer_altdelivery_address2")
            .val(shopId)
            .end()
            .find("#checkout_customer_altdelivery_phone")
            .val(customerPhone)
            .end()
            .trigger("blur");

          const parcelshopMethodsAndProducts = state.shipping?.collections
            .filter((collection) => {
              return collection.selected.method
                .toLowerCase()
                .includes("pakkeshop");
            })
            .map((collection) => {
              return {
                methodId: collection.selected.methodId,
                products: collection.selected.products,
              };
            });

          const selection = state.shipping?.collections.map((collection) => {
            return {
              m: collection.selected.methodId,
              ps: collection.selected.products.map((p) => ({
                id: p.productNumber,
                q: p.qty,
              })),
              pr: collection.selected.price,
            };
          });

          $(".res3 > input#res3").val(
            JSON.stringify(selection ?? { m: 0, ps: [], pr: 0 })
          );

          if (parcelshopMethodsAndProducts === undefined) return;

          // $(".res3 > input#res3").val(
          // 	JSON.stringify(parcelshopMethodsAndProducts),
          // );

          $(".delivery-pickup-change-delvery").show();
        });

      const $parcelshop = $parcelshopHTML.find(
        ".delivery-pickup-gls-item.selected-postal"
      );
      let shopId = $parcelshop.find(".delivery-pickup-gls-shopid").text();
      shopId =
        shopId.toLowerCase().indexOf("pakkeshop") >= 0
          ? shopId
          : shopId.indexOf(":") >= 0
          ? `Pakkeshop: ${shopId.split(":")[1].trim()}`
          : `Pakkeshop: ${shopId}`;
      const shopName = $parcelshop.find(".delivery-pickup-gls-name").text();

      const shopAddress = $parcelshop
        .find(".delivery-pickup-gls-streetname")
        .text();
      const shopCity = $parcelshop
        .find(".delivery-pickup-gls-postal-zip")
        .text(); // Yes, they are switched in the source from DD.
      const shopZip = $parcelshop
        .find(".delivery-pickup-gls-postal-city")
        .text();
      const shopCountry = state.checkout.customer.checkout_customer_country;

      const customerPhone = state.checkout.customer.checkout_customer_phone;

      const customerName = state.checkout.customer.checkout_customer_name;

      $("#checkout_altdelivery_customertype").val(1).trigger("change");

      $deliveryDataForm
        .find("#checkout_customer_altdelivery_country")
        .val((_, value) => (value === shopCountry ? value : shopCountry))
        .trigger("change")
        .end()
        .find("#checkout_customer_altdelivery_zipcode")
        .val(shopZip)
        .end()
        .find("#checkout_customer_altdelivery_city")
        .val(shopCity)
        .end()
        .find("#checkout_customer_altdelivery_name")
        .val(customerName)
        .end()
        .find("#checkout_customer_altdelivery_company")
        .val(shopName)
        .end()
        .find("#checkout_customer_altdelivery_address")
        .val(shopAddress)
        .end()
        .find("#checkout_customer_altdelivery_address2")
        .val(shopId)
        .end()
        .find("#checkout_customer_altdelivery_phone")
        .val(customerPhone)
        .end()
        .trigger("blur");

      console.log("Debug 3: Parcelshop selected");

      console.log({
        methods: $parcelshopMethods,
        parcelshopHTML: $parcelshopHTML,
      });

      if ($('.ds-shipping-method[data-parcelshop="true"]').length > 1) {
        console.log("Debug 3.1: Parcelshop selected");
        $('.ds-shipping-method[data-parcelshop="true"]').each(
          (idx, parcelshopMethod) => {
            const $parcelshopMethod = $(parcelshopMethod);

            const $parcelshopHTMLclone = $parcelshopHTML.clone(true, true);

            $parcelshopHTMLclone
              .attr("data-idx", idx)
              .end()
              .find(".OrderStep2_GLS_RadioBtn")
              .each((_, radio) => {
                const $radio = $(radio);

                $radio.attr("id", ($radio.attr("id") ?? "") + idx);
                $radio.attr("name", ($radio.attr("name") ?? "") + idx);
              });

            $parcelshopHTMLclone
              .find(".delivery-pickup-gls-item")
              .on("click", (event) => {
                const $target = $(event.delegateTarget);

                const targetIdx = $target.data("id");

                const $otherParcelshopMethods = $(
                  ".ds-gls-parsel-shops--container"
                ).not(`[data-idx="${idx}"]`);

                const $otherTargets = $otherParcelshopMethods.find(
                  `.delivery-pickup-gls-item[data-id="${targetIdx}"]`
                );

                $otherTargets
                  .addClass("selected-postal")
                  .prependTo($otherTargets.parent())
                  .find(".delivery-pickup-gls-checkbox")
                  .addClass("checked")
                  .find('input[type="radio"]')
                  .prop("checked", true);
                $otherTargets
                  .siblings(
                    ".shipping-parcel-shop--item.delivery-pickup-gls-item"
                  )
                  .removeClass("selected-postal")
                  .fadeOut()
                  .find(".delivery-pickup-gls-checkbox")
                  .removeClass("checked")
                  .find('input[type="radio"]')
                  .prop("checked", false);
              });

            var psContainer = $parcelshopMethod.find(
              "+ .ds-gls-parsel-shops--container"
            );

            if (psContainer.length > 0) {
              console.log("test 2");
              psContainer.remove();
            }

            $parcelshopMethod.after($parcelshopHTMLclone);
          }
        );
      } else {
        console.log("Debug 3.2: Parcelshop selected");

        // var psContainer = $('.ds-shipping-method[data-parcelshop="true"] + .ds-gls-parsel-shops--container');

        // if (psContainer.length > 0) {
        //   console.log("here?");
        //   psContainer.remove();
        // }

        var newContainer = $parcelshopHTML.clone(true, true);

        if ($(".ds-gls-parsel-shops--container").length > 0) {
          $(".ds-gls-parsel-shops--container").replaceWith(newContainer);
        } else {
          $('.ds-shipping-method.checked[data-parcelshop="true"]').after(
            newContainer
          );
        }

        $('.ds-shipping-method.checked[data-parcelshop="true"]').after(
          "<div style='display: none;'></div>"
        );

        // console.log({ newContainer, psMethod: $('.ds-shipping-method[data-parcelshop="true"]') });

        // while ($('.ds-shipping-method[data-parcelshop="true"]').length < 1) {
        //   await wait(10);
        // }
      }

      console.log("Debug 4: Parcelshop selected");
      // $parcelshopMethods.after($parcelshopHTML);

      checkLabels();
    },
    {
      fireImmediately: true,
      equalityFn: shallow,
    }
  );

  /* const checkParcelshopInterval =  */ setInterval(() => {
    let changesMade = false;
    const state = getState();

    if (
      state.parcelshopSelected() === false ||
      isNullUndefinedOrEmpty($("#checkout_customer_name").val()) ||
      isNullUndefinedOrEmpty($("#checkout_customer_address").val()) ||
      isNullUndefinedOrEmpty($("#checkout_customer_zipcode").val()) ||
      isNullUndefinedOrEmpty($("#checkout_customer_phone").val())
    )
      return;

    const $deliveryDataForm = $(
      "#customer-alternative-delivery > #customer-data > form"
    );

    const $selectedShop = $(
      ".ds-gls-parsel-shops--container .selected-postal"
    ).first();

    let shopId = $selectedShop.find(".delivery-pickup-gls-shopid").text();
    shopId =
      shopId.toLowerCase().indexOf("pakkeshop") >= 0
        ? shopId
        : shopId.indexOf(":") >= 0
        ? `Pakkeshop: ${shopId.split(":")[1].trim()}`
        : `Pakkeshop: ${shopId}`;
    const shopName = $selectedShop.find(".delivery-pickup-gls-name").text();
    const shopAddress = $selectedShop
      .find(".delivery-pickup-gls-streetname")
      .text();
    const shopCity = $selectedShop
      .find(".delivery-pickup-gls-postal-zip")
      .text(); // Yes, they are switched in the source from DD.
    const shopZip = $selectedShop
      .find(".delivery-pickup-gls-postal-city")
      .text();

    const shopCountry = state.checkout.customer.checkout_customer_country;
    const customerPhone = state.checkout.customer.checkout_customer_phone;
    const customerName = state.checkout.customer.checkout_customer_name;

    if (
      $("#checkout_altdelivery_customertype").val() !== 1 &&
      $("#checkout_altdelivery_customertype").val() !== "1"
    ) {
      $("#checkout_altdelivery_customertype").val(1).trigger("change");
      changesMade = true;
    }

    if (
      $deliveryDataForm.find("#checkout_customer_altdelivery_country").val() ===
      null
    ) {
      $deliveryDataForm
        .find("#checkout_customer_altdelivery_country")
        .val((_, value) => (value === shopCountry ? value : shopCountry))
        .trigger("change");
      changesMade = true;
    }

    if (
      $deliveryDataForm.find("#checkout_customer_altdelivery_zipcode").val() ===
        null ||
      $deliveryDataForm.find("#checkout_customer_altdelivery_zipcode").val() ===
        "" ||
      $deliveryDataForm.find("#checkout_customer_altdelivery_zipcode").val() ===
        undefined
    ) {
      $deliveryDataForm
        .find("#checkout_customer_altdelivery_zipcode")
        .val(shopZip);
      changesMade = true;
    }

    if (
      $deliveryDataForm.find("#checkout_customer_altdelivery_city").val() ===
        null ||
      $deliveryDataForm.find("#checkout_customer_altdelivery_city").val() ===
        "" ||
      $deliveryDataForm.find("#checkout_customer_altdelivery_city").val() ===
        undefined
    ) {
      $deliveryDataForm
        .find("#checkout_customer_altdelivery_city")
        .val(shopCity);
      changesMade = true;
    }

    if (
      $deliveryDataForm.find("#checkout_customer_altdelivery_name").val() ===
        null ||
      $deliveryDataForm.find("#checkout_customer_altdelivery_name").val() ===
        "" ||
      $deliveryDataForm.find("#checkout_customer_altdelivery_name").val() ===
        undefined
    ) {
      $deliveryDataForm
        .find("#checkout_customer_altdelivery_name")
        .val(customerName);
      changesMade = true;
    }

    if (
      $deliveryDataForm.find("#checkout_customer_altdelivery_company").val() ===
        null ||
      $deliveryDataForm.find("#checkout_customer_altdelivery_company").val() ===
        "" ||
      $deliveryDataForm.find("#checkout_customer_altdelivery_company").val() ===
        undefined
    ) {
      $deliveryDataForm
        .find("#checkout_customer_altdelivery_company")
        .val(shopName);
      changesMade = true;
    }

    if (
      $deliveryDataForm.find("#checkout_customer_altdelivery_address").val() ===
        null ||
      $deliveryDataForm.find("#checkout_customer_altdelivery_address").val() ===
        "" ||
      $deliveryDataForm.find("#checkout_customer_altdelivery_address").val() ===
        undefined
    ) {
      $deliveryDataForm
        .find("#checkout_customer_altdelivery_address")
        .val(shopAddress);
      changesMade = true;
    }

    if (
      $deliveryDataForm
        .find("#checkout_customer_altdelivery_address2")
        .val() === null ||
      $deliveryDataForm
        .find("#checkout_customer_altdelivery_address2")
        .val() === "" ||
      $deliveryDataForm
        .find("#checkout_customer_altdelivery_address2")
        .val() === undefined
    ) {
      $deliveryDataForm
        .find("#checkout_customer_altdelivery_address2")
        .val(shopId);
      changesMade = true;
    }

    if (
      $deliveryDataForm.find("#checkout_customer_altdelivery_phone").val() ===
        null ||
      $deliveryDataForm.find("#checkout_customer_altdelivery_phone").val() ===
        "" ||
      $deliveryDataForm.find("#checkout_customer_altdelivery_phone").val() ===
        undefined
    ) {
      $deliveryDataForm
        .find("#checkout_customer_altdelivery_phone")
        .val(customerPhone);
      changesMade = true;
    }

    if (changesMade) {
      $deliveryDataForm.trigger("blur");
    }
  }, 250);
};

const collectionTranslationsMap = {
  "26": {
    collectionHeader: "Vælg forsendelsesmetode for:",
  },

  "27": {
    collectionHeader: "Velg fraktmetode for:",
  },

  "28": {
    collectionHeader: "Välj fraktmetod för:",
  },
} as const;

async function makeCollections() {
  const state = getState();

  const $ddShippingMethods = $("#shipping-methods");

  $ddShippingMethods.hide();

  if (
    state.shipping === undefined ||
    state.shipping === null ||
    state.shipping.sessionId === ""
  ) {
    const shippingProducts = getShippingProducts();

    const shippingPrices = await getShippingPrices({
      sessionId: getSessionId(),
      products: shippingProducts,
      currency: siteCurrency,
      zipCode: getZipCode(),
      deliveryCountry: await getDeliveryCountry(true),
      shippingName: siteShippingName,
    });

    state.setShipping(shippingPrices);

    window.loadOrderSummary(true);
  }

  const $dsShippingCollectionsContainer = $(".ds-shipping-collections").length
    ? $(".ds-shipping-collections").empty()
    : $(
        /* html */ `<div class="ds-shipping-collections" id="ds-shipping-methods"></div>`
      );

  if (
    state.shipping?.collections === undefined ||
    state.shipping.collections === null ||
    state.shipping.collections.length <= 0
  )
    return;

  const customerType = parseInt(
    $("#checkout_customertype").val()?.toString() ?? "-1"
  );

  for (const collection of state.shipping.collections) {
    const $dsShippingCollection = $(
      /* html */ `<fieldset id="dsShippingCollection-${collection.collectionId}" class="ds-shipping-collection"></div>`
    );

    const $dsShippingCollectionHeader = $(
      /* html */ `<div class="ds-shipping-collection-header" style="margin-bottom: .3rem">${collectionTranslationsMap[LanguageID].collectionHeader}</div>`
    );

    $dsShippingCollection.append($dsShippingCollectionHeader);

    const $dsShippingCollectionProductList = $(
      /* html */ `<ul class="shippingCollectionProductList" style="padding: 0; margin-top: 0; list-style-type: none;"></ul>`
    );

    const $dsShippingCollectionProducts = collection.selected.products
      // .filter((p) => basketProducts.some((bp) => bp.productNumber === p))
      .map((shippingProduct) => {
        const product = basketProducts.find(
          (product) => product.productNumber === shippingProduct.productNumber
        );

        if (product === undefined) return $('<li style="display: none;"></li>');

        const thumbnail = `${product.image.slice(
          0,
          product.image.lastIndexOf(".")
        )}-t${product.image.slice(product.image.lastIndexOf("."))}`;

        return $(/* html */ `
          <li>
            <a style="display: flex; gap: .5rem; color: #444; text-decoration: none; align-items: center;" href="${product.url}">
              <img src="${thumbnail}" style="display: block; height: 17px; aspect-ratio: 1 / 1; border-radius: 3px; box-shadow: 0 0 0px 1px #da0000" />
              <b>${product.name}</b><span style="font-size: smaller">(${product.productNumber})</span>
            </a>
          </li>
        `);
      });

    $dsShippingCollectionProductList.append(...$dsShippingCollectionProducts);

    $dsShippingCollection.append($dsShippingCollectionProductList);

    let filteredMethods: Method[] = collection.methods
      .filter(
        (method) =>
          method &&
          method.available &&
          (customerType <= 0 &&
          method.method.toLowerCase().includes("kun erhverv") === false
            ? true
            : customerType > 0
            ? true
            : false)
      )
      .sort((a, b) => a.price - b.price)
      .reduce((methods, method) => {
        if (methods.length <= 0) return (methods = [method]);

        if (
          methods.some(
            (m) =>
              m.title.toLowerCase() === method.title.toLowerCase() &&
              m.price <= method.price
          )
        ) {
          return (methods = [...methods]);
        }

        return (methods = [...methods, method]);
      }, [] as Method[]);

    for (const method of filteredMethods) {
      const dsShippingMethod = $(
        makeMethodTemplate({
          id: method.methodId,
          collectionId: collection.collectionId,
          name: method.method,
          title: isNotNullUndefinedOrEmpty(method.title)
            ? method.title
            : method.method,
          description: method.description,
          image: method.imageUrl,
          price: Math.ceil(
            method.price * ((await getSessionValues()).vatPercentage / 100 + 1)
          ),
          currencyCode: siteCurrency,
          selected: collection.selected.methodId === method.methodId,
        })
      );

      if (
        collection.selected.methodId === method.methodId &&
        method.method.toLowerCase().includes("pakkeshop")
      ) {
        const parcelshopMethodsAndProducts = state.shipping?.collections
          .filter((collection) => {
            return collection.selected.method
              .toLowerCase()
              .includes("pakkeshop");
          })
          .map((collection) => {
            return {
              methodId: collection.selected.methodId,
              products: collection.selected.products,
            };
          });

        const selection = state.shipping?.collections.map((collection) => {
          return {
            m: collection.selected.methodId,
            ps: collection.selected.products.map((p) => ({
              id: p.productNumber,
              q: p.qty,
            })),
            pr: collection.selected.price,
          };
        });

        $(".res3 > input#res3").val(
          JSON.stringify(selection ?? { m: 0, ps: [], pr: 0 })
        );

        if (parcelshopMethodsAndProducts === undefined) return;

        // $(".res3 > input#res3").val(
        // 	JSON.stringify(parcelshopMethodsAndProducts),
        // );
      }

      dsShippingMethod.on("click", async (event: JQuery.ClickEvent) => {
        const $target = $(event.delegateTarget);

        // if ($target.hasClass('checked')) return;
        const state = getState();

        if ($target.data("parcelshop")) {
          const $deliveryDataForm = $(
            "#customer-alternative-delivery > #customer-data > form"
          );

          const $parcelshop = $target
            .siblings(".ds-gls-parsel-shops--container")
            .find(".delivery-pickup-gls-item.selected-postal");
          let shopId = $parcelshop.find(".delivery-pickup-gls-shopid").text();
          shopId =
            shopId.toLowerCase().indexOf("pakkeshop") >= 0
              ? shopId
              : shopId.indexOf(":") >= 0
              ? `Pakkeshop: ${shopId.split(":")[1].trim()}`
              : `Pakkeshop: ${shopId}`;

          const shopName = $parcelshop.find(".delivery-pickup-gls-name").text();
          const shopAddress = $parcelshop
            .find(".delivery-pickup-gls-streetname")
            .text();
          const shopCity = $parcelshop
            .find(".delivery-pickup-gls-postal-zip")
            .text(); // Yes, they are switched in the source from DD.
          const shopZip = $parcelshop
            .find(".delivery-pickup-gls-postal-city")
            .text();
          const shopCountry = state.checkout.customer.checkout_customer_country;

          const customerPhone = state.checkout.customer.checkout_customer_phone;

          const customerName = state.checkout.customer.checkout_customer_name;

          $("#checkout_altdelivery_customertype").val(1).trigger("change");

          $deliveryDataForm
            .find("#checkout_customer_altdelivery_country")
            .val((_, value) => (value === shopCountry ? value : shopCountry))
            .trigger("change")
            .end()
            .find("#checkout_customer_altdelivery_zipcode")
            .val(shopZip)
            .end()
            .find("#checkout_customer_altdelivery_city")
            .val(shopCity)
            .end()
            .find("#checkout_customer_altdelivery_name")
            .val(customerName)
            .end()
            .find("#checkout_customer_altdelivery_company")
            .val(shopName)
            .end()
            .find("#checkout_customer_altdelivery_address")
            .val(shopAddress)
            .end()
            .find("#checkout_customer_altdelivery_address2")
            .val(shopId)
            .end()
            .find("#checkout_customer_altdelivery_phone")
            .val(customerPhone)
            .end()
            .trigger("blur");

          const parcelshopMethodsAndProducts = state.shipping?.collections
            .filter((collection) => {
              return collection.selected.method
                .toLowerCase()
                .includes("pakkeshop");
            })
            .map((collection) => {
              return {
                methodId: collection.selected.methodId,
                products: collection.selected.products,
              };
            });

          const selection = state.shipping?.collections.map((collection) => {
            return {
              m: collection.selected.methodId,
              ps: collection.selected.products.map((p) => ({
                id: p.productNumber,
                q: p.qty,
              })),
              pr: collection.selected.price,
            };
          });

          $(".res3 > input#res3").val(
            JSON.stringify(selection ?? { m: 0, ps: [], pr: 0 })
          );

          if (parcelshopMethodsAndProducts === undefined) return;

          const $altDeliveryCheckbox = $<HTMLInputElement>(
            "#checkout_use_alternative_delivery_address"
          );

          $(".checkout-row")
            .has("#checkout_use_alternative_delivery_address")
            .hide();

          if ($altDeliveryCheckbox.prop("checked") === false) {
            $altDeliveryCheckbox.prop("checked", true);

            $altDeliveryCheckbox.val(1);

            ky.get(
              `/shop/AjaxCheckOutWrapper.asp?action=togglealternativedelivery&checked=1&_=${Date.now()}`
              // {
              //   fetch: window.kyFetch,
              // }
            );
          }

          $(
            "#customer-data > form > fieldset > .checkout-row > div.div-checkout-checkbox"
          ).addClass("alt-parcelshop");

          $("#customer-alternative-delivery")
            .attr("tabindex", "-1")
            .addClass("alt-parcelshop")
            .show();

          $(
            "#customer-alternative-delivery .column-heading h3:not(.block-number)"
          ).hide();

          setState((state) => {
            state.checkout.customer.checkout_use_alternative_delivery_address =
              "1";
          });

          addNumber();

          $.get(
            `/shop/AjaxCheckOutWrapper.asp?action=togglealternativedelivery&checked=1&_=${Date.now()}`
          );
        } else {
          // $(".res3 > input#res3").val("");
          const selection = state.shipping?.collections.map((collection) => {
            return {
              m: collection.selected.methodId,
              ps: collection.selected.products.map((p) => ({
                id: p.productNumber,
                q: p.qty,
              })),
              pr: collection.selected.price,
            };
          });

          $(".res3 > input#res3").val(
            JSON.stringify(selection ?? { m: 0, ps: [], pr: 0 })
          );

          const $deliveryDataForm = $(
            "#customer-alternative-delivery > #customer-data > form"
          );

          state.clearDelivery();

          $("#checkout_altdelivery_customertype").val(0).trigger("change");

          $deliveryDataForm
            .find("#checkout_customer_altdelivery_country")
            .val([])
            .trigger("change")
            .end()
            .find("#checkout_customer_altdelivery_zipcode")
            .val("")
            .end()
            .find("#checkout_customer_altdelivery_city")
            .val("")
            .end()
            .find("#checkout_customer_altdelivery_company")
            .val("")
            .end()
            .find("#checkout_customer_altdelivery_name")
            .val("")
            .end()
            .find("#checkout_customer_altdelivery_address")
            .val("")
            .end()
            .find("#checkout_customer_altdelivery_address2")
            .val("")
            .end()
            .find("#checkout_customer_altdelivery_phone")
            .val("")
            .end()
            .trigger("blur");

          const $altDeliveryCheckbox = $<HTMLInputElement>(
            "#checkout_use_alternative_delivery_address"
          );

          if ($altDeliveryCheckbox.prop("checked") === true) {
            // $altDeliveryCheckbox.trigger('click');

            $altDeliveryCheckbox.prop("checked", false);

            $altDeliveryCheckbox.val(0);

            ky.get(
              `/shop/AjaxCheckOutWrapper.asp?action=togglealternativedelivery&checked=0&_=${Date.now()}`
              // {
              //   fetch: window.kyFetch,
              // }
            );
          } else {
            ky.get(
              `/shop/AjaxCheckOutWrapper.asp?action=togglealternativedelivery&checked=0&_=${Date.now()}`
              // {
              //   fetch: window.kyFetch,
              // }
            );
          }

          if (
            $('.ds-shipping-method.checked[data-parcelshop="true"]').length ===
            0
          ) {
            $(".checkout-row")
              .has("#checkout_use_alternative_delivery_address")
              .show();

            $("#customer-alternative-delivery")
              .removeAttr("tabindex")
              .removeClass("alt-parcelshop");

            $(
              "#customer-data > form > fieldset > .checkout-row > div.div-checkout-checkbox"
            ).removeClass("alt-parcelshop");

            $(
              "#customer-alternative-delivery .column-heading h3:not(.block-number)"
            ).show();

            setState((state) => {
              state.checkout.customer.checkout_use_alternative_delivery_address =
                "0";
            });

            addNumber();

            $("#customer-alternative-delivery").hide();

            $.get(
              `/shop/AjaxCheckOutWrapper.asp?action=togglealternativedelivery&checked=0&_=${Date.now()}`
            );
          }
        }

        await changeCollectionMethod({
          collectionId: collection.collectionId,
          methodId: method.methodId,
        });
      });

      $dsShippingCollection.append(dsShippingMethod);
    }
    $dsShippingCollectionsContainer.append($dsShippingCollection);
  }

  $ddShippingMethods.before($dsShippingCollectionsContainer);
}

function checkLabels() {
  $('input[type="radio"]:not(.spanned)')
    .addClass("spanned")
    .after('<span class="checkmark"></span>'),
    $(".checked").removeClass("checked"),
    $('input[type="radio"]:checked')
      .parent()
      .addClass("checked")
      .parents("label")
      .addClass("checked"),
    $('input[type="checkbox"]:not(.spanned)')
      .addClass("spanned")
      .after('<span class="checkmark"></span>'),
    $("#confirm-accept-customerdata, #confirm-accepterms").addClass(
      "unchecked"
    ),
    $('input[type="checkbox"]:checked')
      .parent()
      .removeClass("unchecked")
      .addClass("checked");
}

function addNumber() {
  $(".block-number").remove();
  var t = 0;
  $("h2:visible:not(.checkout-order-summary-headline), h3:visible").each(
    function (_) {
      if ($(this).parent().is("#customer-login")) return;
      (t += 1), $(this).before(`<h3 class="block-number">${t}</h3>`);
    }
  );
}

function getZipCode() {
  const state = getState();

  const altZip = state.checkout.delivery.checkout_customer_altdelivery_zipcode;
  const zip = state.checkout.customer.checkout_customer_zipcode;

  if (altZip !== undefined && altZip !== "" && !isNaN(Number(altZip))) {
    return Number(altZip);
  } else if (zip !== undefined && zip !== "" && !isNaN(Number(zip))) {
    return Number(zip);
  }

  return undefined;
}
