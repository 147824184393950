import { persist, subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import create from "zustand/vanilla";
// import produce from 'immer';
import Cookies from "js-cookie";
import { nanoid } from "nanoid";
import type {
	SessionValuesResponse,
	ShippingPricesResponse
} from "../types/global.js";

export interface StateProduct {
	index?: number;
	quantity: number;
	unitPrice: number;
	unitPriceIncVAT: number;
	specialOfferPrice: number;
	specialOfferPriceIncVAT: number;
	totalPrice?: number;
	totalPriceIncVAT?: number;
	isSpecialOffer: boolean;
	productNumber: string;
	productName: string;
	productImageUrl: string;
}

export interface Checkout {
	customer: CustomerData;
	delivery: DeliveryData;
}

export interface CustomerData {
	checkout_customertype: string;
	checkout_customer_company: string;
	checkout_customer_cvrnr: string;
	checkout_customer_ean: string;
	checkout_customer_name: string;
	checkout_customer_address: string;
	checkout_customer_zipcode: string;
	checkout_customer_city: string;
	checkout_customer_country: string;
	checkout_customer_phone: string;
	checkout_customer_email: string;
	checkout_note: string;
	checkout_use_alternative_delivery_address: string;
	res1type: string;
	res1: string;
	res2type: string;
	res2: string;
	res3type: string;
	res3: string;
	res4type: string;
	res4: string;
	res5type: string;
	res5: string;
	fieldcount: string;
}

export interface DeliveryData {
	checkout_altdelivery_customertype: string;
	checkout_customer_altdelivery_company: string;
	checkout_customer_altdelivery_ean: string;
	checkout_customer_altdelivery_name: string;
	checkout_customer_altdelivery_address: string;
	checkout_customer_altdelivery_zipcode: string;
	checkout_customer_altdelivery_city: string;
	checkout_customer_altdelivery_country: string;
	checkout_customer_altdelivery_phone: string;
	deliveryfieldcount: string;
}

export interface CustomerFormDataObject {
	name:
		| "checkout_customertype" /** Customer Type */
		| "checkout_customer_company"
		| "checkout_customer_cvrnr"
		| "checkout_customer_ean"
		| "checkout_customer_name"
		| "checkout_customer_address"
		| "checkout_customer_zipcode"
		| "checkout_customer_city"
		| "checkout_customer_country"
		| "checkout_customer_phone"
		| "checkout_customer_email"
		| "checkout_note"
		| "res1type"
		| "res1"
		| "res2type"
		| "res2"
		| "res3type"
		| "res3"
		| "res4type"
		| "res4"
		| "res5type"
		| "res5"
		| "fieldcount"
		| "checkout_use_alternative_delivery_address";
	value: string;
}

export interface DeliveryFormDataObject {
	name:
		| "checkout_altdelivery_customertype"
		| "checkout_customer_altdelivery_company"
		| "checkout_customer_altdelivery_ean"
		| "checkout_customer_altdelivery_name"
		| "checkout_customer_altdelivery_address"
		| "checkout_customer_altdelivery_zipcode"
		| "checkout_customer_altdelivery_city"
		| "checkout_customer_altdelivery_country"
		| "checkout_customer_altdelivery_phone"
		| "deliveryfieldcount";
	value: string;
}

export type StateProductRecord = Record<string | number | symbol, StateProduct>;

export interface DynamicShippingState {
	checkout: Checkout;
	clearDelivery: () => void;
	products: StateProductRecord;
	productsAdd: (product: StateProduct) => void;
	productsRemove: (product: StateProduct | string | number) => void;
	productsRemoveAll: () => void;
	productsIncQty: (
		product: StateProduct | string | number,
		by?: number,
	) => void;
	productsDecQty: (
		product: StateProduct | string | number,
		by?: number,
	) => void;
	productsSetQty: (product: StateProduct | string | number, to: number) => void;
	productsTotalQty: () => number;
	productsTotalPrice: () => number;
	productsTotalPriceIncVAT: () => number;
	resetState: () => void;
	shipping: ShippingPricesResponse | null;
	setShipping: (shipping: ShippingPricesResponse) => void;
	parcelshopSelected: () => boolean;
	session: SessionValuesResponse | null;
	setSession: (session: SessionValuesResponse) => void;
	sessionId: () => string;
	serviceProducts: StateProduct[];
	setServiceProducts: (serviceProducts: StateProduct[]) => void;
}

type initialDynamicShippingState = Pick<
	DynamicShippingState,
	"checkout" | "products" | "shipping" | "session" | "serviceProducts"
>;

const initialState: initialDynamicShippingState = {
	checkout: {
		customer: {
			checkout_customertype: "0",
			checkout_customer_company: "",
			checkout_customer_cvrnr: "",
			checkout_customer_ean: "",
			checkout_customer_name: "",
			checkout_customer_address: "",
			checkout_customer_zipcode: "",
			checkout_customer_city: "",
			checkout_customer_country: "",
			checkout_customer_phone: "",
			checkout_customer_email: "",
			checkout_note: "",
			checkout_use_alternative_delivery_address: "0",
			res1type: "",
			res1: "",
			res2type: "",
			res2: "",
			res3type: "",
			res3: "",
			res4type: "",
			res4: "",
			res5type: "",
			res5: "",
			fieldcount: "",
		},
		delivery: {
			checkout_altdelivery_customertype: "",
			checkout_customer_altdelivery_company: "",
			checkout_customer_altdelivery_ean: "",
			checkout_customer_altdelivery_name: "",
			checkout_customer_altdelivery_address: "",
			checkout_customer_altdelivery_zipcode: "",
			checkout_customer_altdelivery_city: "",
			checkout_customer_altdelivery_country: "",
			checkout_customer_altdelivery_phone: "",
			deliveryfieldcount: "",
		},
	},
	products: {},
	shipping: null,
	session: null,
	// sessionId: null,
	serviceProducts: [],
};

const store = create<DynamicShippingState>()(
	subscribeWithSelector(
		persist(
			immer((set, get) => ({
				...initialState,
				sessionId: () => Cookies.get("ds_cookie_id") ?? nanoid(),
				clearDelivery: () => {
					set((state) => {
						state.checkout.delivery = {
							checkout_altdelivery_customertype: "",
							checkout_customer_altdelivery_company: "",
							checkout_customer_altdelivery_ean: "",
							checkout_customer_altdelivery_name: "",
							checkout_customer_altdelivery_address: "",
							checkout_customer_altdelivery_zipcode: "",
							checkout_customer_altdelivery_city: "",
							checkout_customer_altdelivery_country: "",
							checkout_customer_altdelivery_phone: "",
							deliveryfieldcount: "",
						};
					}, true);
				},
				productsAdd: (product) =>
					set((state) => {
						if (state.products[product.productNumber] !== undefined) {
							state.products[product.productNumber].quantity +=
								product.quantity;
						} else {
							state.products[product.productNumber] = product;
						}
					}),
				productsRemove: (product) => {
					set((state) => {
						if (typeof product === "string" || typeof product === "number") {
							// rome-ignore lint/performance/noDelete: <explanation>
							delete state.products[product];
						} else if (typeof product === "object") {
							// rome-ignore lint/performance/noDelete: <explanation>
							delete state.products[product.productNumber];
						}
					});
				},
				productsRemoveAll: () => {
					set((state) => {
						state.products = {};
						if (state.shipping !== null) {
							state.shipping.total = 0;
						}
					}, true);
				},
				productsIncQty: (product, by = 1) => {
					set((state) => {
						if (typeof product === "string" || typeof product === "number") {
							state.products[product].quantity += by;
						} else if (typeof product === "object") {
							state.products[product.productNumber].quantity += by;
						}
					});
				},
				productsDecQty: (product, by = 1) => {
					set((state) => {
						if (typeof product === "string" || typeof product === "number") {
							state.products[product].quantity -= by;

							if (state.products[product].quantity <= 0) {
								// rome-ignore lint/performance/noDelete: <explanation>
								delete state.products[product];
							}
						} else if (typeof product === "object") {
							state.products[product.productNumber].quantity -= by;

							if (state.products[product.productNumber].quantity <= 0) {
								// rome-ignore lint/performance/noDelete: <explanation>
								delete state.products[product.productNumber];
							}
						}
					});
				},
				productsSetQty: (product, to) => {
					set((state) => {
						if (typeof product === "string" || typeof product === "number") {
							state.products[product].quantity = to;

							if (state.products[product].quantity <= 0) {
								// rome-ignore lint/performance/noDelete: <explanation>
								delete state.products[product];
							}
						} else if (typeof product === "object") {
							state.products[product.productNumber].quantity = to;

							if (state.products[product.productNumber].quantity <= 0) {
								// rome-ignore lint/performance/noDelete: <explanation>
								delete state.products[product.productNumber];
							}
						}
					});
				},
				productsTotalQty: () => {
					const total = Object.values(get().products).reduce(
						(acc, product) => acc + product.quantity,
						0,
					);
					return total;
				},
				productsTotalPrice: () => {
					const total = Object.values(get().products).reduce(
						(acc, product) => acc + product.unitPriceIncVAT * product.quantity,
						0,
					);
					return total;
				},
				productsTotalPriceIncVAT: () => {
					const payFee = get().session?.basketPayMethodFee ?? 0;
					const shippingFee = Math.ceil((get().shipping?.total ?? 0) * 1.25);
					// const oldShippingFee = get().session?.basketShippingFee ?? 0;
					const total = Object.values(get().products).reduce(
						(acc, product) => acc + product.unitPriceIncVAT * product.quantity,
						0,
					);
					return total + payFee + shippingFee;
				},
				resetState: () => {
					localStorage.removeItem("sessionValues");

					set((state) => {
						state.products = initialState.products;
						state.session = initialState.session;
						// state.sessionId = '';
						state.shipping = initialState.shipping;
						state.checkout = initialState.checkout;
						// serviceProducts: initialState.serviceProducts,
					});
				},
				setShipping: (shipping) => {
					set((state) => {
						state.shipping = shipping;
					}, true);
				},
				parcelshopSelected: () => {
					return (
						get().shipping?.collections?.some((collection) =>
							collection.selected.method.toLowerCase().includes("pakkeshop"),
						) ?? false
					);
				},
				setSession: (session) => {
					set((state) => {
						state.session = session;
					});
				},
				setServiceProducts: (serviceProducts) => {
					set((state) => {
						state.serviceProducts = serviceProducts;
					});
				},
			})),
			{
				name: "dynamicShipping",
			},
		),
	),
);

export const { getState, setState, subscribe, destroy } = store;
