import type { MakeShippingMethodTemplate } from '../types/global.js';

export const makeMethodTemplate: MakeShippingMethodTemplate = ({
  id,
  collectionId,
  name,
  title,
  description,
  image,
  price,
  currencyCode,
  selected,
}) => {
  return /* html */ `
    <label for="checkout-shipping-${collectionId}-${id}" class="${
    selected ? 'checked ds-shipping-method' : 'ds-shipping-method'
  }" data-parcelshop="${name.toLowerCase().includes('pakkeshop')}">
      <table class="checkout-shipping-table">
        <tbody>
          <tr>
            <td class="checkout-shipping-radio-cell ${
              selected ? 'checked' : ''
            }">
              <input
                autocomplete="false"
                type="radio"
                name="checkout-shipping-${collectionId}"
                value="${id}"
                id="checkout-shipping-${collectionId}-${id}"
                class="checkout-radio checkout-shipping-radio spanned"
                ${selected ? 'checked="true"' : ''}
              />
              <span class="checkmark"></span>
            </td>
            <td class="checkout-shipping-description-cell">
              ${title}
              <span class="shipping-fee" data-fee="${price}" data-pickup="${name.toLowerCase().includes('afhentning')}">
                (${price.toLocaleString(siteLocale, {
                  style: 'currency',
                  currency: currencyCode,
                  currencyDisplay: 'code',
                })})
              </span>
              <div class="shipping-message">
                ${description}
              </div>
              <!-- ${image ? `<img src="${image}" />` : ''} -->
            </td>
          </tr>
        </tbody>
      </table>
    </label>
  `;
};
