import Cookies from "js-cookie";
import ky from "ky";
import pAll from "p-all";
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/fixed";
import { ShippingProduct } from "../types/global.js";
import { getShippingPrices } from "../v3/shippingApi.getShippingPrices.js";
import { getState } from "../v3/store.js";
import {
  getDeliveryCountry,
  getSessionId,
  getSessionValues,
} from "./shopFunctions.js";

export const initShipping = async () => {
  const state = getState();

  console.log("initShipping");

  if (location.href.includes("order4.html")) {
    state.resetState();
    Cookies.remove("ds_cookie_id");
    await ky.get("/shop/?SessionExpired=1");
    // await ky.get("/shop/?SessionExpired=1", { fetch: window.kyFetch });
    return;
  }

  const shippingProducts = getShippingProducts();

  const shippingPrices = await getShippingPrices({
    sessionId: getSessionId(),
    products: shippingProducts,
    currency: siteCurrency,
    deliveryCountry: await getDeliveryCountry(),
    shippingName: siteShippingName,
  });

  console.log(shippingPrices);

  state.setShipping(shippingPrices);

  const shippingFixer = async () => {
    const sessionId = getSessionId();
    const state = getState();
    const session = await getSessionValues(true);

    const sessionShippingProducts = session.basket.filter((product) =>
      product.productNumber.toLowerCase().startsWith("fragt-")
    );

    const currentSessionShippingProduct = sessionShippingProducts.find(
      (p) => p.productNumber === `fragt-${sessionId}`
    );

    if (
      currentSessionShippingProduct !== undefined &&
      sessionShippingProducts.length === 1 &&
      currentSessionShippingProduct.quantity === 1
    ) {
      clearIntervalAsync(checkForShippingProduct);
      return;
    }

    let shippingTasks = [];

    if (currentSessionShippingProduct === undefined) {
      const shipping = await getShippingPrices({
        sessionId: getSessionId(),
        products: getShippingProducts(),
        shippingName: siteShippingName,
        deliveryCountry: await getDeliveryCountry(),
        currency: siteCurrency,
      });

      state.setShipping(shipping);

      shippingTasks.push(
        () =>
          ky.get(`/shop/basket.asp?add=1&amount=1&productid=fragt-${sessionId}`)
        // ky.get(`/shop/basket.asp?add=1&amount=1&productid=fragt-${sessionId}`, { fetch: window.kyFetch })
      );

      localStorage.setItem("ajaxAddedProduct", "true");
    }

    if (
      currentSessionShippingProduct &&
      currentSessionShippingProduct.quantity !== 1
    ) {
      shippingTasks.push(() =>
        ky.get(
          `/shop/basket.asp?caller=showbasket&setAmount=1&keyid=${currentSessionShippingProduct.index}`
          // `/shop/basket.asp?caller=showbasket&setAmount=1&keyid=${currentSessionShippingProduct.index}`, { fetch: window.kyFetch }
        )
      );
    }

    if (sessionShippingProducts.length > 1) {
      const invalidShippingProducts = sessionShippingProducts.filter(
        (p) => p.productNumber !== `fragt-${sessionId}`
      );

      invalidShippingProducts.forEach((isp) =>
        shippingTasks.push(() =>
          ky.get(
            `/shop/basket.asp?remove=1&keyid=${isp.index}`
            //   {
            //   fetch: window.kyFetch,
            // }
          )
        )
      );
    }

    await pAll(shippingTasks);

    if (shippingTasks.length > 0 && location.href.includes("checkout.html")) {
      loadOrderSummary(true);
    }
  };

  shippingFixer();

  const checkForShippingProduct = setIntervalAsync(shippingFixer, 1500);

  if (location.href.includes("order4.html")) {
    state.resetState();
    Cookies.remove("ds_cookie_id");
    await ky.get("/shop/?SessionExpired=1");
    // await ky.get("/shop/?SessionExpired=1", { fetch: window.kyFetch });
    return;
  }
};

export const getShippingProducts = (): ShippingProduct[] => {
  return basketProducts
    .filter(
      (p) =>
        p.productNumber !== "" &&
        p.productNumber.toLowerCase() !== "retur60" &&
        p.productNumber.toLowerCase() !== "workshop" &&
        p.productNumber.toLowerCase().startsWith("rabat-") === false &&
        p.productNumber.toLowerCase().startsWith("fragt-") === false &&
        p.url !== ""
    )
    .map((product) => {
      return {
        productNumber: product.productNumber,
        qty: product.quantity,
      };
    });
};
