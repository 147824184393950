import type { CollectionChange } from "../types/global.js";
import { getShippingProducts } from "../v3/shipping.js";
import { getShippingPrices } from "./shippingApi.getShippingPrices.js";
import { getState } from "./store.js";

export const changeCollectionMethod = async (change: CollectionChange) => {
	const state = getState();

	const shippingProducts = getShippingProducts();

	const shippingPrices = await getShippingPrices({
		sessionId: getSessionId(),
		products: shippingProducts,
		currency: siteCurrency,
		deliveryCountry: await getDeliveryCountry(),
		shippingName: siteShippingName,
		changes: [change],
	});

	state.setShipping(shippingPrices);

	loadOrderSummary(true);
};
