import type { DynamicShippingState } from './store.js';

export const makeShippingProducts = (state: DynamicShippingState) => {
  return Object.values(state.products)
    .filter(
      (product) =>
        state.serviceProducts.find(
          (serviceProduct) =>
            serviceProduct.productNumber === product.productNumber
        ) === undefined && product.productNumber.includes('fragt-') === false
    )
    .map((product) => ({
      productNumber: product.productNumber,
      qty: product.quantity,
    }));

  // return Object.values(state.products)
  //   .map((product) => ({
  //     productNumber: product.productNumber,
  //     qty: product.quantity,
  //   }))
  //   .filter(
  //     (product) =>
  //       state.serviceProducts.findIndex(
  //         (serviceProduct) =>
  //           serviceProduct.productNumber === product.productNumber
  //       ) === -1 && product.productNumber.includes('fragt-') === false
  //   );
};

export const toProperCase = (str: string) => {
  return str.replace(/\b[\p{L}\p{N}_]+/gu, function (s) {
    return s.charAt(0).toUpperCase() + s.substring(1).toLowerCase();
  });
};

export const isNotNullUndefinedOrEmpty = (str: string | null | undefined) => {
  return str !== null && str !== undefined && str.trim() !== '';
};

// rome-ignore lint/suspicious/noExplicitAny: <explanation>
export  const isNullUndefinedOrEmpty = (arg: any) => {
  return arg === null || arg === undefined || arg.trim() === '';
};

export const wait = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
