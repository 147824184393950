import ky from "ky";
import pAll from "p-all";
import type { ShippingProduct } from "../types/global.js";
import { getShippingPrices } from "../v3/shippingApi.getShippingPrices.js";
import { getState } from "../v3/store.js";
import { getShippingProducts } from "./shipping.js";
import { getDeliveryCountry, getSessionId } from "./shopFunctions.js";

export const initBasket = () => {
  $("#showbasketContent .amount a").each((_, e) => {
    $(e).attr("href", `${$(e).attr("href")}&RefreshAllPrices=1`);

    $(e).on("click", async (event) => {
      event.preventDefault();

      const url = $(e).attr("href");

      if (url === undefined) return;

      const productNumber = $(e).parents(".amount").data("id").toString();

      const quantityChange = url.includes("inc=1") ? 1 : url.includes("dec=1") ? -1 : 0;

      const shippingProducts = getShippingProducts()
        .filter((p) => !(url.includes("remove=1") && p.productNumber === productNumber))
        .map<ShippingProduct>((p) => {
          return {
            productNumber: p.productNumber,
            qty: p.productNumber === productNumber ? p.qty + quantityChange : p.qty,
          };
        });

      console.log(shippingProducts);

      const [shipping, _] = await pAll([
        async () =>
          getShippingPrices({
            sessionId: getSessionId(),
            products: shippingProducts,
            shippingName: siteShippingName,
            deliveryCountry: await getDeliveryCountry(),
            currency: siteCurrency,
          }),
        () => ky.get(url),
        // () => ky.get(url, { fetch: window.kyFetch }),
      ]);

      console.log(shipping);

      getState().setShipping(shipping);

      location.href = `${location.pathname}?RefreshAllPrices=1`;
    });
  });
};
