import type {
  CollectionChange,
  ShippingPricesResponse,
  ShippingProduct,
} from "../types/global.js";
import { shippingApi } from "./shippingApi.js";

interface GetShippingPricesOptions {
  sessionId: string;
  shippingName: string;
  deliveryCountry: string;
  zipCode?: number;
  currency: string;
  products?: ShippingProduct[];
  changes?: CollectionChange[];
}

type GetShippingPrices = ({
  sessionId,
  shippingName,
  deliveryCountry,
  currency,
  products,
  changes,
}: GetShippingPricesOptions) => Promise<ShippingPricesResponse>;

export const getShippingPrices: GetShippingPrices = async ({
  sessionId,
  shippingName,
  deliveryCountry,
  zipCode,
  currency,
  products,
  changes,
}) => {
  const response = await shippingApi
    .post("shipping", {
      json: {
        sessionId,
        shippingName,
        deliveryCountry,
        zipCode,
        siteId: parseInt(LanguageID),
        currency,
        products,
        changes,
      },
    })
    .json<ShippingPricesResponse>();

  // localStorage.setItem('shippingPrices', JSON.stringify(response));

  return response;
};
