import type { CustomerData, DeliveryData } from './store.js';
import { setState } from './store.js';

export const customerDataHandler = (event: JQuery.ChangeEvent | JQuery.BlurEvent) => {
  const $customerForm = $<HTMLFormElement>(event.delegateTarget);

  const customerData = $customerForm.serializeArray().reduce((result, item) => {
    result[item.name] = item.value;
    return result;
  }, {} as Record<string, string>) as unknown as CustomerData;

  setState((state) => {
    state.checkout.customer = customerData;
  });
};

export const deliveryDataHandler = (event: JQuery.ChangeEvent | JQuery.BlurEvent) => {
  const $deliveryForm = $<HTMLFormElement>(event.delegateTarget);

  const deliveryData = $deliveryForm.serializeArray().reduce((result, item) => {
    result[item.name] = item.value;
    return result;
  }, {} as Record<string, string>) as unknown as DeliveryData;

  setState((state) => {
    state.checkout.delivery = deliveryData;
  });
};
